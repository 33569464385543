/**
 * @generated SignedSource<<a66997df5a378138f097c4e0bdef452c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CategorizationRequestQuery_Query$variables = {
  uuid: string;
};
export type CategorizationRequestQuery_Query$data = {
  readonly contract: {
    readonly categorizationRequest: {
      readonly accepted: boolean | null | undefined;
      readonly errorMessage: string | null | undefined;
      readonly id: string;
      readonly requestId: string;
      readonly responseBody: any | null | undefined;
      readonly responseStatus: number | null | undefined;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
};
export type CategorizationRequestQuery_Query = {
  response: CategorizationRequestQuery_Query$data;
  variables: CategorizationRequestQuery_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "concreteType": "ContractType",
    "kind": "LinkedField",
    "name": "contract",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CategorizationRequestType",
        "kind": "LinkedField",
        "name": "categorizationRequest",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requestId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "responseStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "responseBody",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accepted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "errorMessage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CategorizationRequestQuery_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CategorizationRequestQuery_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "818ed20e65a001927992e764ea455f81",
    "id": null,
    "metadata": {},
    "name": "CategorizationRequestQuery_Query",
    "operationKind": "query",
    "text": "query CategorizationRequestQuery_Query(\n  $uuid: String!\n) {\n  contract(uuid: $uuid) {\n    id\n    categorizationRequest {\n      id\n      requestId\n      responseStatus\n      responseBody\n      accepted\n      errorMessage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "20f3218d39b879cbf9bcb24b1f1f515d";

export default node;
