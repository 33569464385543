import { Backdrop, CircularProgress } from "@mui/material";
import React, { Component, type ErrorInfo, type ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  isReloadSessionInProgress: boolean;
}

export class ChunkLoadErrorBoundary extends Component<Props, State> {
  timer: ReturnType<typeof setTimeout> | null = null;

  public override state: State = {
    hasError: false,
    isReloadSessionInProgress: false,
  };

  public override componentDidUpdate(
    _prevProps: Props,
    prevState: State,
  ): void {
    if (
      !prevState.hasError &&
      this.state.hasError &&
      isReloadSessionInProgress()
    ) {
      this.timer = setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }

  public override componentWillUnmount(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    if (error.name === "ChunkLoadError") {
      return {
        hasError: true,
        isReloadSessionInProgress: isReloadSessionInProgress(),
      };
    }

    return { hasError: false, isReloadSessionInProgress: false };
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error.name === "ChunkLoadError") {
      return;
    }

    throw error;
  }

  public override render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "400px",
          }}
        >
          <h1 style={{ fontWeight: "bold" }}>Oops!</h1>
          <p>
            {this.state.isReloadSessionInProgress ? (
              <>
                There was an issue performing the requested action. Reloading
                the page to get the latest application version...
              </>
            ) : (
              <>
                There was an issue performing the requested action. Could not
                retrieve the latest application version.
              </>
            )}
          </p>
        </section>
      </div>
    );
  }
}

type ReloadCounter = { expiry: number };

function getReloadCounter() {
  const reloadCounterUnparsed = localStorage.getItem(
    "chunk-load-error-boundary-reload-counter",
  );

  const newReloadCounter = {
    expiry: new Date().getTime() + 30000, // we will retry for 30s
  };

  let reloadCounter = reloadCounterUnparsed
    ? (JSON.parse(reloadCounterUnparsed) as ReloadCounter)
    : null;
  if (!reloadCounter) {
    setReloadCounter(newReloadCounter);
    return newReloadCounter;
  }

  const isPreviousReloadSessionExpired =
    // add a buffer time of 20s for sessions to avoid infinitely reloading
    new Date().getTime() > reloadCounter.expiry + 20000;
  if (isPreviousReloadSessionExpired) {
    setReloadCounter(newReloadCounter);
    return newReloadCounter;
  }

  return reloadCounter;
}

function setReloadCounter(updatedReloadCounter: ReloadCounter) {
  localStorage.setItem(
    "chunk-load-error-boundary-reload-counter",
    JSON.stringify(updatedReloadCounter),
  );
}

function isReloadSessionInProgress() {
  const reloadCounter = getReloadCounter();
  const isReloadSessionInProgress = new Date().getTime() < reloadCounter.expiry;
  return isReloadSessionInProgress;
}
