/**
 * @generated SignedSource<<e1a674197afd9507d495b39cc73b671c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrgContractTypesQuery_Query$variables = Record<PropertyKey, never>;
export type OrgContractTypesQuery_Query$data = {
  readonly account: {
    readonly organization: {
      readonly orgContractTypes: ReadonlyArray<{
        readonly customName: string | null | undefined;
        readonly id: string;
        readonly isActive: boolean | null | undefined;
        readonly name: string;
        readonly objectType: string;
        readonly requiredForms: ReadonlyArray<{
          readonly id: string;
          readonly name: string | null | undefined;
        } | null | undefined> | null | undefined;
        readonly uuid: string | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type OrgContractTypesQuery_Query = {
  response: OrgContractTypesQuery_Query$data;
  variables: OrgContractTypesQuery_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "OrgContractTypeType",
  "kind": "LinkedField",
  "name": "orgContractTypes",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormType",
      "kind": "LinkedField",
      "name": "requiredForms",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrgContractTypesQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrgContractTypesQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a2b25429d457d4a6a49c8cdc2bf5be7",
    "id": null,
    "metadata": {},
    "name": "OrgContractTypesQuery_Query",
    "operationKind": "query",
    "text": "query OrgContractTypesQuery_Query {\n  account {\n    organization {\n      orgContractTypes {\n        id\n        uuid\n        name\n        customName\n        isActive\n        requiredForms {\n          id\n          name\n        }\n        objectType\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "314dfcab3b8923e5b00d0834e7b839a2";

export default node;
