import { graphql } from "react-relay";

export default graphql`
  query OrgFormsQuery_Query {
    account {
      organization {
        defaultForm {
          id
        }
        forms {
          id
          name
        }
        requiredFieldsEnabled
      }
    }
  }
`;
