import { graphql } from "react-relay";

export default graphql`
  query AccountRoleQuery_Query($forContract: String!) {
    account {
      roleInfo(forcontract: $forContract) {
        invitedRole
        canDelete
        isAdmin
        isAcmanager
        readOnly
      }
    }
  }
`;
