import { encodeQueryParams } from "~/utils/params";
import { getCsrfToken } from "~/utils/auth";
import { postFilter } from "./postFilter";
import { useQueryClient } from "react-query";

const contractListKey = ["/api/v1/contracts/xl/", "/api/v1/upcoming/"];

export function useResetContractList() {
  const queryClient = useQueryClient();
  return () => queryClient.resetQueries({ queryKey: contractListKey });
}

export function contractListOptions(
  queryParams: Record<string, any>,
  skip: boolean = false,
) {
  const encodedParams = encodeQueryParams(queryParams);
  return {
    queryKey: [...contractListKey, encodedParams.toString(), skip],
    queryFn: async () => {
      if (skip) {
        return null;
      }
      const [getParams, postParams] = postFilter(encodedParams);
      const url =
        queryParams.view == "upcoming"
          ? "/api/v1/upcoming/"
          : "/api/v1/contracts/xl/";
      const response = await fetch(`${url}?${getParams}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": getCsrfToken(),
        },
        body: postParams,
      });
      if (!response.ok) {
        throw new Error("Error fetching data");
      }
      return await response.json();
    },
    cacheTime: 0,
  };
}
