import React, { createContext, useContext, useMemo } from "react";

// We want to limit what we add here to relatively static data.
// This will require a refresh to be updated from the server, so
// we should limit this to data that we'd expect to remain static
// until a refresh occurs.
type AuthenticationContextType = {
  organization: {
    id: number;
    // Not base64 encoded version of`OrganizationType:<id>` since
    // it's not coming from relay.
    uuid: string;
    billing: { isFreeTrial: boolean; features: string[] };
    dateFormat: string | null;
    approvalsEnabled: boolean;
    esignEnabled: boolean;
  } | null;
  account: {
    email: string;
    isVerifieddevice: boolean;
    isAuthenticated: boolean;
    isPreDfa: boolean;
    isAdmin: boolean;
    isAcmanager: boolean;
    // Role and logoutInMinutes also technically
    // live on the invitation, but are the safest
    // when fetched off of the account since it takes into
    // account invitationless users.
    role: string;
    logoutInMinutes: number;
  } | null;
};

const AuthenticationContext = createContext<
  AuthenticationContextType | undefined
>(undefined);

export const AuthenticationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const authData = useMemo(
    () => JSON.parse(document.querySelector("#auth-data")!.textContent || "{}"),
    [],
  );

  return (
    <AuthenticationContext.Provider value={authData}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  // No context provided
  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within a AuthenticationProvider",
    );
  }

  if (context.account) {
    context.account.isAuthenticated = !!context.account.isVerifieddevice;
    context.account.isPreDfa = context.account.isVerifieddevice === false;
  }

  return context;
};
