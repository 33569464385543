import { graphql } from "react-relay";

export default graphql`
  fragment CategorizationRequestFragment_fragment on ContractType {
    categorizationRequest {
      id
      requestId
      responseStatus
      responseBody
      accepted
      errorMessage
    }
  }
`;
