/**
 * @generated SignedSource<<d1a49afb20c7c5fe932309dd4fe9cb1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategorizationRequestFragment_fragment$data = {
  readonly categorizationRequest: {
    readonly accepted: boolean | null | undefined;
    readonly errorMessage: string | null | undefined;
    readonly id: string;
    readonly requestId: string;
    readonly responseBody: any | null | undefined;
    readonly responseStatus: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CategorizationRequestFragment_fragment";
};
export type CategorizationRequestFragment_fragment$key = {
  readonly " $data"?: CategorizationRequestFragment_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategorizationRequestFragment_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategorizationRequestFragment_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CategorizationRequestType",
      "kind": "LinkedField",
      "name": "categorizationRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requestId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "responseStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "responseBody",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accepted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "errorMessage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContractType",
  "abstractKey": null
};

(node as any).hash = "e630e067ccba0d09ea8d8a6c489c1dc4";

export default node;
