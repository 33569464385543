window._ = require("underscore");
window.jQuery = require("jquery");
window.$ = window.jQuery;

require("jstz");

Object.assign(window, require("~/utils/global"));

import React from "react";
import ReactDOM from "react-dom";
import Root from "./root";
import { initializeSentry } from "./sentry";

document.addEventListener("DOMContentLoaded", function (event) {
  initializeSentry();
  const target = document.getElementById("main");
  ReactDOM.render(<Root />, target);
});
