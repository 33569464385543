import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  Navigate,
  useParams,
} from "react-router-dom";
import { RelayEnvironmentProvider } from "react-relay";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import environment from "~/RelayEnvironment";
import { initializeSentry } from "./sentry";
import Link from "@mui/material/Link";
import BaselineThemed from "./BaselineThemed";
import { AuthenticationProvider } from "./components/Authentication";
import { ChunkLoadErrorBoundary } from "./components/ChunkLoadErrorBoundary";

const Main = React.lazy(() => import("./ReactMain"));
const Login = React.lazy(() => import("~/authentication/components/Login"));
const IpNotWhitelisted = React.lazy(
  () => import("~/authentication/components/IpNotWhitelisted"),
);
const DashboardPage = React.lazy(
  () => import("~/dashboard/components/DashboardPage"),
);

const ContractsListPage = React.lazy(
  () => import("~/contracts/components/ContractsListPage"),
);
const ContractsListPageV2 = React.lazy(
  () => import("~/contracts/components/ContractsListPageV2"),
);
const ResetPassword = React.lazy(
  () => import("~/authentication/components/ResetPassword"),
);
const ForgotPassword = React.lazy(
  () => import("~/authentication/components/ForgotPassword"),
);
const AcceptInvitePage = React.lazy(
  () => import("~/authentication/components/AcceptInvitePage"),
);
const ContractDetailPage = React.lazy(
  () => import("~/contracts/components/ContractDetailPage"),
);
const ExportRequestPage = React.lazy(
  () => import("~/contracts/components/ExportRequestPage"),
);
const SettingsPage = React.lazy(
  () => import("~/settings/components/SettingsPage"),
);
const ViewerWindow = React.lazy(
  () => import("~/contracts/components/ViewerWindow"),
);
const DocusignRedirect = React.lazy(
  () => import("~/contracts/components/DocusignRedirect"),
);
const ContractSummaryPage = React.lazy(
  () => import("~/components/ContractSummary"),
);
const HelloErrorsPage = React.lazy(
  () => import("~/components/HelloErrorsPage"),
);
const HelloWorldPage = React.lazy(() => import("~/components/HelloWorldPage"));
const IntakePage = React.lazy(() => import("~/components/Intake"));
const TemplatePage = React.lazy(() => import("~/components/Template"));
const TemplatePageV2 = React.lazy(() => import("~/components/TemplatePageV2"));

export const queryClient = new QueryClient();

function RedirectContracts() {
  const { uuid } = useParams();
  return <Navigate to={`/v3/contracts/${uuid}`} replace />;
}

function InvalidPasswordTokenMessage() {
  return (
    <div style={{ paddingTop: "20px", height: "calc(100vh - 72px)" }}>
      <center>
        <p>The token you have received has expired or is invalid.</p>
        <p>
          Please go <Link href="/user/forgot_password/">HERE</Link> to request
          another password reset.
        </p>
      </center>
    </div>
  );
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <Sentry.ErrorBoundary
          fallback={({ error }) => <div>{error.message}</div>}
        >
          <ChunkLoadErrorBoundary>
            <Suspense fallback={<div />}>
              <Main />
            </Suspense>
          </ChunkLoadErrorBoundary>
        </Sentry.ErrorBoundary>
      }
    >
      <Route path="/contracts/:uuid" element={<RedirectContracts />} />
      <Route path="/dashboard/:uuid" element={<RedirectContracts />} />
      <Route path="/onboarding/:uuid/*" element={<React.Fragment />} />
      <Route
        path="/contracts"
        element={
          <BaselineThemed>
            {waffle.flag_is_active("load_dashboard_folder_cache_aync") ? (
              <ContractsListPageV2 />
            ) : (
              <ContractsListPage />
            )}
          </BaselineThemed>
        }
      />
      <Route
        path="/user/export_request/:uuid/"
        element={
          <BaselineThemed>
            <ExportRequestPage />
          </BaselineThemed>
        }
      />
      <Route
        path="/user/accept_invite/"
        element={
          <BaselineThemed>
            <AcceptInvitePage />
          </BaselineThemed>
        }
      />
      <Route
        path="/dashboard"
        element={
          <BaselineThemed>
            <DashboardPage />
          </BaselineThemed>
        }
      />
      <Route
        path="/v3/attch/:uuid/document"
        element={
          <BaselineThemed>
            <ViewerWindow attachment />
          </BaselineThemed>
        }
      />
      <Route
        path="/v3/contracts/:uuid/summary"
        element={
          <BaselineThemed>
            <ContractSummaryPage />
          </BaselineThemed>
        }
      />
      <Route
        path="/v3/contracts/:uuid/document"
        element={
          <BaselineThemed>
            <ViewerWindow />
          </BaselineThemed>
        }
      />
      <Route
        path="/v3/contracts/:uuid"
        element={
          <BaselineThemed>
            <ContractDetailPage />
          </BaselineThemed>
        }
      />
      <Route
        path="/docusign"
        element={
          <BaselineThemed>
            <DocusignRedirect />
          </BaselineThemed>
        }
      />
      <Route
        path="/settings/:section?"
        element={
          <BaselineThemed>
            <SettingsPage />
          </BaselineThemed>
        }
      />
      <Route
        path="/settingsv2/:section?"
        element={
          <BaselineThemed>
            <SettingsPage />
          </BaselineThemed>
        }
      />
      <Route
        path="/intake/:uuid?/:token?"
        element={
          <BaselineThemed>
            <IntakePage />
          </BaselineThemed>
        }
      />
      <Route
        path="/template/:uuid?/:token?"
        element={
          <BaselineThemed>
            {waffle.flag_is_active("templates_page_v2_enabled") ? (
              <TemplatePageV2 />
            ) : (
              <TemplatePage />
            )}
          </BaselineThemed>
        }
      />
      <Route
        path="/templatev1/:uuid?/:token?"
        element={
          <BaselineThemed>
            <TemplatePage />
          </BaselineThemed>
        }
      />
      <Route
        path="/templatev2/:uuid?/:token?"
        element={
          <BaselineThemed>
            <TemplatePageV2 />
          </BaselineThemed>
        }
      />
      <Route
        path="/user/forgot_password/"
        element={
          <BaselineThemed>
            <ForgotPassword />
          </BaselineThemed>
        }
      />
      <Route path="/user/reset_password/:token" element={<ResetPassword />} />
      <Route
        path="/user/welcome_set_password/:token"
        element={<ResetPassword />}
      />
      <Route path="/user/invited_user/:token" element={<ResetPassword />} />
      <Route
        path="/user/invalid_password_token/"
        element={
          <BaselineThemed>
            <InvalidPasswordTokenMessage />
          </BaselineThemed>
        }
      />
      <Route path="/hello_errors" element={<HelloErrorsPage />} />
      <Route path="/hello_world" element={<HelloWorldPage />} />
      <Route path="/empty" element={/* for testing */ <React.Fragment />} />
      <Route
        element={
          <div
            className="login-container"
            id="lgct"
            style={{ marginTop: "92px" }}
          >
            <Outlet />
          </div>
        }
      >
        <Route path="/register" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/ip_notwhitelisted" element={<IpNotWhitelisted />} />
        <Route path="*" element={<Login />} />
      </Route>
    </Route>,
  ),
);

export default function Root() {
  return (
    <AuthenticationProvider>
      <RelayEnvironmentProvider {...{ environment }}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} fallbackElement={<div />} />
        </QueryClientProvider>
      </RelayEnvironmentProvider>
    </AuthenticationProvider>
  );
}

export function mountReactRouter() {
  initializeSentry();

  function attemptMount(retries) {
    try {
      const target = document.getElementById("main");
      if (!target) {
        throw "retry";
      }
      ReactDOM.render(<Root />, target);
    } catch (e) {
      if (retries <= 3) {
        setTimeout(() => attemptMount(retries + 1), retries);
      }
    }
  }

  setTimeout(() => attemptMount(0));
}
