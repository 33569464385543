import { graphql } from "react-relay";

export default graphql`
  mutation ChangeContractTypeMutation(
    $uuid: String!
    $contractType: String
    $aiAccept: Boolean
    $objectType: String
  ) {
    updateContract(
      input: {
        uuid: $uuid
        contractType: $contractType
        aiAccept: $aiAccept
        objectType: $objectType
      }
    ) {
      contract {
        id
        currentFormMissingRequiredFields
        form {
          ...FormSelect_formFragment
        }
        ...FormSelect_contractFragment
        contractType
        customContractType {
          id
          uuid
          name
        }
        requiredForms {
          id
          name
        }
      }
    }
  }
`;
