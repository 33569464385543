/**
 * @generated SignedSource<<09aead8f7c1b34c504b6056fb94f4be8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrgFormsQuery_Query$variables = Record<PropertyKey, never>;
export type OrgFormsQuery_Query$data = {
  readonly account: {
    readonly organization: {
      readonly defaultForm: {
        readonly id: string;
      } | null | undefined;
      readonly forms: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null | undefined;
      } | null | undefined> | null | undefined;
      readonly requiredFieldsEnabled: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type OrgFormsQuery_Query = {
  response: OrgFormsQuery_Query$data;
  variables: OrgFormsQuery_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "FormType",
  "kind": "LinkedField",
  "name": "defaultForm",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "FormType",
  "kind": "LinkedField",
  "name": "forms",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredFieldsEnabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrgFormsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrgFormsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationType",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "407a95c599d6e38045200982e53cd724",
    "id": null,
    "metadata": {},
    "name": "OrgFormsQuery_Query",
    "operationKind": "query",
    "text": "query OrgFormsQuery_Query {\n  account {\n    organization {\n      defaultForm {\n        id\n      }\n      forms {\n        id\n        name\n      }\n      requiredFieldsEnabled\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "83d47a707ed8828f3fbd6345622c9a4e";

export default node;
