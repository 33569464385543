import React, { createContext, useContext, useState } from "react";
import Snackbar, { type SnackbarProps } from "@mui/material/Snackbar";

type SnackbarContextType = {
  openSnackbar: (message: string, props?: SnackbarProps) => void;
  openSnackbarWithProps: (props: SnackbarProps) => void;
  closeSnackbar: () => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);

type SnackbarProviderProps = { children?: React.ReactNode };

/*
 * Snackbar context provider and hook that you can use to trigger snackbars from anywhere in your component tree.
 *
 * This also allows for overriding any of the Snackbar's props (including the child content).
 *
 * @example
 * ```tsx
 * // Initialize the SnackbarProvider React context on your page
 * <SnackbarProvider>
 *  <App />
 * </SnackbarProvider>
 *
 * // Use the useSnackbar hook to trigger snackbars
 * const MyComponent = () => {
 *  const { openSnackbar } = useSnackbar();
 *  const handleClick = () => {
 *    openSnackbarWithProps({
 *      message: "Hello, world!",
 *      autoHideDuration: 6000,
 *    });
 *  };
 *
 * return <button onClick={handleClick}>Show snackbar</button>;
 * ```
 *
 * TODO:
 * - Convert existing Snackbar usage to useSnackbar hook
 *
 */
export function SnackbarProvider(props: SnackbarProviderProps) {
  const [snackbarProps, setSnackbarProps] = useState<
    SnackbarProps | null | undefined
  >(null);

  const { children, ...defaultProps } = props;

  function openSnackbar(
    message: string,
    props?: Omit<SnackbarProps, "message">,
  ) {
    if (message == "Not Logged In") {
      window.location.href = "/login?next=" + location.pathname;
    } else {
      setSnackbarProps({ message, ...(props ?? {}) });
    }
  }

  return (
    <SnackbarContext.Provider
      value={{
        openSnackbar,
        openSnackbarWithProps: (props) => setSnackbarProps(props),
        closeSnackbar: () => setSnackbarProps(null),
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={4000}
        open={snackbarProps !== null}
        onClose={() => setSnackbarProps(null)}
        ClickAwayListenerProps={{ onClickAway: () => null }}
        {...defaultProps}
        {...snackbarProps}
      />
    </SnackbarContext.Provider>
  );
}

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }

  return context;
};
