/**
 * @generated SignedSource<<e15ca5dce4c9e325415bac520d5ad9da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeContractTypeMutation$variables = {
  aiAccept?: boolean | null | undefined;
  contractType?: string | null | undefined;
  objectType?: string | null | undefined;
  uuid: string;
};
export type ChangeContractTypeMutation$data = {
  readonly updateContract: {
    readonly contract: {
      readonly contractType: string | null | undefined;
      readonly currentFormMissingRequiredFields: boolean | null | undefined;
      readonly customContractType: {
        readonly id: string;
        readonly name: string;
        readonly uuid: string;
      } | null | undefined;
      readonly form: {
        readonly " $fragmentSpreads": FragmentRefs<"FormSelect_formFragment">;
      } | null | undefined;
      readonly id: string;
      readonly requiredForms: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null | undefined;
      } | null | undefined> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"FormSelect_contractFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type ChangeContractTypeMutation = {
  response: ChangeContractTypeMutation$data;
  variables: ChangeContractTypeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "aiAccept"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objectType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "aiAccept",
        "variableName": "aiAccept"
      },
      {
        "kind": "Variable",
        "name": "contractType",
        "variableName": "contractType"
      },
      {
        "kind": "Variable",
        "name": "objectType",
        "variableName": "objectType"
      },
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentFormMissingRequiredFields",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomContractTypeType",
  "kind": "LinkedField",
  "name": "customContractType",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = [
  (v5/*: any*/),
  (v9/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "FormType",
  "kind": "LinkedField",
  "name": "requiredForms",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fieldType",
  "storageKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v16 = {
  "kind": "InlineFragment",
  "selections": (v11/*: any*/),
  "type": "FieldSetType",
  "abstractKey": null
},
v17 = [
  (v5/*: any*/),
  (v9/*: any*/),
  (v14/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeContractTypeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "form",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "FormSelect_formFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FormSelect_contractFragment"
              },
              (v7/*: any*/),
              (v10/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChangeContractTypeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateContractPayload",
        "kind": "LinkedField",
        "name": "updateContract",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractType",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FormType",
                "kind": "LinkedField",
                "name": "form",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormSectionType",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": true,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v5/*: any*/),
                              (v9/*: any*/),
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ValueListItemType",
                                "kind": "LinkedField",
                                "name": "choices",
                                "plural": true,
                                "selections": (v11/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "CustomFieldType",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v5/*: any*/),
                              (v9/*: any*/),
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "choices",
                                "plural": true,
                                "selections": [
                                  (v13/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v11/*: any*/),
                                    "type": "StringChoiceType",
                                    "abstractKey": null
                                  },
                                  (v15/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "BuiltinFieldType",
                            "abstractKey": null
                          },
                          (v16/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "requiredFields",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v17/*: any*/),
                        "type": "CustomFieldType",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v17/*: any*/),
                        "type": "BuiltinFieldType",
                        "abstractKey": null
                      },
                      (v16/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v12/*: any*/),
              (v7/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "edde4146e371d15abc7c6048841c3b5c",
    "id": null,
    "metadata": {},
    "name": "ChangeContractTypeMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeContractTypeMutation(\n  $uuid: String!\n  $contractType: String\n  $aiAccept: Boolean\n  $objectType: String\n) {\n  updateContract(input: {uuid: $uuid, contractType: $contractType, aiAccept: $aiAccept, objectType: $objectType}) {\n    contract {\n      id\n      currentFormMissingRequiredFields\n      form {\n        ...FormSelect_formFragment\n        id\n      }\n      ...FormSelect_contractFragment\n      contractType\n      customContractType {\n        id\n        uuid\n        name\n      }\n      requiredForms {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment FormSelect_contractFragment on ContractType {\n  uuid\n  currentFormMissingRequiredFields\n  requiredForms {\n    id\n  }\n}\n\nfragment FormSelect_formFragment on FormType {\n  id\n  uuid\n  name\n  sections {\n    name\n    fields {\n      __typename\n      ... on CustomFieldType {\n        id\n        name\n        fieldType\n        choices {\n          id\n          name\n        }\n      }\n      ... on BuiltinFieldType {\n        id\n        name\n        fieldType\n        choices {\n          __typename\n          ... on StringChoiceType {\n            id\n            name\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n      ... on FieldSetType {\n        id\n        name\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n  requiredFields {\n    __typename\n    ... on CustomFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on BuiltinFieldType {\n      id\n      name\n      fieldType\n    }\n    ... on FieldSetType {\n      id\n      name\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c876a99c42a64d21b2b46361b67f25a";

export default node;
