import { getCookie } from "~/utils/cookies";

export async function logout(suppressRedirect) {
  try {
    const auth2 = window.gapi.auth2.getAuthInstance();
    await auth2.signOut();
  } catch (e) {}

  invalidateRelayStore();
  const response = await fetch("/api/v1/auth/logout/", {
    method: "POST",
    headers: { "X-CSRFToken": getCsrfToken() },
  });
  await response.json();
  if (!suppressRedirect) {
    window.location = "/login";
  }
}

function getCsrfTokenSynchronously() {
  const xhr = new XMLHttpRequest();
  // Set the third parameter to false for a synchronous request
  xhr.open("GET", "/api/v1/auth/csrf/", false); // false makes the request synchronous

  xhr.send(); // Execution blocks here until the request completes
  console.log("Response:", xhr.responseText);
  if (xhr.status === 200) {
    const json = JSON.parse(xhr.responseText);
    const csrfToken = json.csrfToken; // Assuming the token is in the 'token' field
    return csrfToken;
  } else {
    // Handle HTTP error status
    console.error("Failed to get CSRF token:", xhr.statusText);
  }
}

export function getCsrfToken() {
  return getCookie("csrftoken");
}
