// Override parameters to send filter params via POST
export function postFilter(params: URLSearchParams) {
  const getParams = new URLSearchParams(params.toString());
  const postParams = new URLSearchParams();
  if (params.has("filter")) {
    // Only do this if there is a filter, so the query params are
    // still an indication about whether there are filters or not.
    params.getAll("filter").forEach((f) => postParams.append("filter", f));
    getParams.set("filter", "<POST>");
  }
  return [getParams, postParams] as const;
}
