import React from "react";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Theme from "~/Theme";

export default function BaselineThemed({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Theme}>
        <ScopedCssBaseline>{children}</ScopedCssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
