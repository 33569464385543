export function encodeQueryParams(queryParams: Record<string, any>) {
  function encodeParam(k: string, v: string | object) {
    return { k, v: typeof v == "object" ? JSON.stringify(v) : v };
  }

  return new URLSearchParams(
    Object.entries(queryParams)
      .flatMap(([k, v]) =>
        v == null
          ? []
          : Array.isArray(v)
            ? v.map((x) => encodeParam(k, x))
            : encodeParam(k, v),
      )
      .map(({ k, v }) => [k, v]),
  );
}
