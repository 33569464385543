import { graphql } from "react-relay";
import "~/authentication/queries/CategorizationRequestFragment";

export default graphql`
  query CategorizationRequestQuery_Query($uuid: String!) {
    contract(uuid: $uuid) {
      id
      ...CategorizationRequestFragment_fragment @relay(mask: false)
    }
  }
`;
