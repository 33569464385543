import { graphql } from "react-relay";

export default graphql`
  query OrgContractTypesQuery_Query {
    account {
      organization {
        orgContractTypes {
          id
          uuid
          name
          customName
          isActive
          requiredForms {
            id
            name
          }
          objectType
        }
      }
    }
  }
`;
