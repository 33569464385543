import { graphql } from "react-relay";

export default graphql`
  query ContractFormsContract_Query($contractUuid: String!) {
    contract(uuid: $contractUuid) {
      id
      uuid
      isactive
      totalValue
      currencySymbol
      currentFormMissingRequiredFields
      builtinFieldValues {
        field {
          id
        }
        ...ContractForms_fieldValueFragment
      }
      customFieldValues {
        field {
          id
        }
        ...ContractForms_fieldValueFragment
      }
      form {
        id
      }
      annotationsJson
      contractType
      ...ContractForms_StandardDates_ContractFragment
      ...ContractForms_ContractDatesFragment
      ...Parties_ContractMyCompaniesFragment
      ...Parties_ContractCounterpartiesFragment
      ...Parties_ContractPartiesFragment
      ...FormSelect_contractFragment
      ...FormSelect_contractFragment @relay(mask: false)
      ...UpdateContractFromTemplateDialog_contractFragment
    }
  }
`;
