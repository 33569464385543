import { graphql } from "react-relay";

export default graphql`
  query ContractQuery_Query(
    $uuid: String!
    $commentsFeatureEnabled: Boolean = false
    $commentsFirst: Int = 10
  ) {
    contract(uuid: $uuid) {
      ...ContractFragment_fragment @relay(mask: false)
      ...CommentsSectionContent_contractFragment
        @relay(mask: true)
        @arguments(commentsFirst: $commentsFirst)
        @include(if: $commentsFeatureEnabled)
    }
  }
`;
