const {
  invalidateRelayStore,
  preloadQuery,
  loadQuery,
  ACCOUNTROLE_QUERY,
  CONTRACT_QUERY,
  CONTRACT_FORMS_CONTRACT_QUERY,
  ORG_FORMS_QUERY,
  ORG_CONTRACT_TYPES_QUERY,
  CATEGORIZATION_REQUEST_QUERY,
  CHANGE_CONTRACT_TYPE_MUTATION,
} = require("~/ReactMain");
const { default: parseISO } = require("date-fns/parseISO");
const { decode: decodeHtmlEntities } = require("html-entities");
const { default: localObjStore } = require("~/LocalObjStore");
const { LicenseInfo } = require("@mui/x-data-grid-premium");
LicenseInfo.setLicenseKey(
  "64cb387c5ad1936b08dab051b54f0856Tz05NTAxOSxFPTE3NTM2MjgxMzQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
);

// global:
Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

Date.prototype.format = function (format) {
  var me = this;
  format = format.toUpperCase();
  var D = "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday".split(","),
    M =
      "January,February,March,April,May,June,July,August,September,October,November,December".split(
        ",",
      );
  return format.replace(
    /a|A|Z|S(SS)?|ss?|mm?|HH?|hh?|D{1,4}|M{1,4}|YY(YY)?|'([^']|'')*'/g,
    function (str) {
      var c1 = str.charAt(0),
        ret =
          str.charAt(0) == "'"
            ? (c1 = 0) || str.slice(1, -1).replace(/''/g, "'")
            : str == "a"
              ? me.getHours() < 12
                ? "am"
                : "pm"
              : str == "A"
                ? me.getHours() < 12
                  ? "AM"
                  : "PM"
                : str == "Z"
                  ? ("+" + -me.getTimezoneOffset() / 60)
                      .replace(/^\D?(\D)/, "$1")
                      .replace(/^(.)(.)$/, "$10$2") + "00"
                  : c1 == "S"
                    ? me.getMilliseconds()
                    : c1 == "s"
                      ? me.getSeconds()
                      : c1 == "H"
                        ? me.getHours()
                        : c1 == "h"
                          ? me.getHours() % 12 || 12
                          : c1 == "D" && str.length > 2
                            ? D[me.getDay()].slice(0, str.length > 3 ? 9 : 3)
                            : c1 == "D"
                              ? me.getDate()
                              : c1 == "M" && str.length > 2
                                ? M[me.getMonth()].slice(
                                    0,
                                    str.length > 3 ? 9 : 3,
                                  )
                                : c1 == "m"
                                  ? me.getMinutes()
                                  : c1 == "M"
                                    ? me.getMonth() + 1
                                    : ("" + me.getFullYear()).slice(
                                        -str.length,
                                      );
      return c1 && str.length < 4 && ("" + ret).length < str.length
        ? ("00" + ret).slice(-str.length)
        : ret;
    },
  );
};

Date.prototype.isValid = function () {
  // An invalid date object returns NaN for getTime() and NaN is the only
  // object not strictly equal to itself.
  return this.getTime() === this.getTime();
};

function timeAgo(date, dateFormat) {
  const dateDiff = (datepart, fromdate, todate) => {
    const diff = todate - fromdate;
    const divideBy = {
      days: 1000 * 60 * 60 * 24,
      hours: 1000 * 60 * 60,
    };

    return Math.floor(diff / divideBy[datepart]);
  };

  const now = new Date();
  const sinceDate = new Date(date);

  const days = dateDiff("days", sinceDate, now);
  if (days) {
    if (days > 7) {
      return sinceDate.format(dateFormat);
    }
    if (days > 1) {
      return days + " days ago";
    }
    return "1 day ago";
  }

  const hours = dateDiff("hours", sinceDate, now);
  if (hours) {
    if (hours > 1) {
      return hours + " hours ago";
    }
    return "1 hour ago";
  }

  return "within the hour";
}

function SelectText(obj, start, stop) {
  try {
    var mainDiv = $(obj)[0],
      startNode = mainDiv.childNodes[0],
      endNode = mainDiv.childNodes[0];

    startNode.nodeValue = startNode.nodeValue.trim();
    var range = document.createRange();
    range.setStart(startNode, start);
    range.setEnd(endNode, stop + 1);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    return range;
  } catch (e) {}
} //SelectText

function xonmouseover(row, classid) {
  $(row).addClass(classid);
}

function xonmouseout(row, classid, addclass) {
  $(row).removeClass(classid);
  if (addclass) {
    $(row).addClass(addclass);
  }
}

function getTimeZone() {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
}

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    try {
      var result =
        a[property].toLowerCase() < b[property].toLowerCase()
          ? -1
          : a[property].toLowerCase() > b[property].toLowerCase()
            ? 1
            : 0;
    } catch (e) {
      result = 0;
    }

    return result * sortOrder;
  };
}

function format_date_string(dval, date_format) {
  if (!(Object.prototype.toString.call(dval) === "[object Date]")) {
    if (dval) {
      if (dval.indexOf("T") > 0) {
        dval = dval.split("/").join("-");
        dval = new Date(dval);
      } else {
        [year, month, day] = dval.split("-").map((x) => parseInt(x));
        dval = new Date(year, month - 1, day);
      }
    }
  }
  if (dval) {
    dval = dval.format(date_format);
  }

  return dval;
}

function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function parseerrordata(data) {
  var ret = "";
  try {
    console.log(JSON.stringify(data));

    for (var err in data) {
      if (data.hasOwnProperty(err)) {
        var attr = data[err];
        ret = ret + err + " " + attr + "<br />";
      }
    }
  } catch (e) {
    console.log(e);
  }
  if (ret == "") {
    try {
      ret = data.message;
    } catch (e) {}
  }
  return ret;
}

function defaultColumns(view, date_format) {
  var cols = [];

  if (view == "default") {
    cols = [
      {
        name: "Folder",
        field: "folder",
        enableCellEdit: false,
        enableHiding: false,
        width: "14%",
        resizable: true,
        sortorder: 0,
      },
      {
        name: "Name",
        field: "title",
        cellClass: "gd_auto",
        enableHiding: false,
        resizable: true,
        width: "34%",
        sortorder: 1,
      },
      {
        name: "Value",
        field: "display_value",
        enableCellEdit: true,
        cellClass: "gd_auto",
        enableHiding: false,
        width: "10%",
        resizable: true,
        sortorder: 2,
      },
      {
        name: "Counterparty",
        field: "counterparty",
        cellClass: "gd_auto",
        enableCellEdit: true,
        enableHiding: false,
        width: "16%",
        resizable: true,
        sortorder: 3,
      },
      {
        name: "Effective date",
        field: "startdate",
        cellFilter: "date:'" + date_format + "'",
        enableCellEdit: true,
        width: "12%",
        enableHiding: false,
        sortorder: 4,
      },
      {
        name: "Termination date",
        field: "terminationdate",
        cellFilter: "date:'" + date_format + "'",
        enableCellEdit: true,
        enableHiding: false,
        cellClass: "gd_auto",
        resizable: true,
        sortorder: 5,
      },
      {
        name: "",
        field: "edit",
        headerCellTemplate:
          '<div   style="text-align:left; margin-top:5px;margin-left:6px;"><input type="checkbox" style="width: 15px;height: 15px;" ng-model="grid.appScope.selectall" ng-click="grid.appScope.editall($event)" ng-disabled="readonly || isloading " /></div>',
        // type: 'boolean',
        pinnedLeft: true,
        enableColumnMoving: false,
        enableColumnResizing: false,
        sortorder: -1,
        resizable: false,
        width: 30,
      },
    ];
  } else if (view == "upcoming") {
    cols = [
      {
        name: "Folder",
        field: "folder",
        enableCellEdit: false,
        enableHiding: false,
        width: "16%",
        resizable: true,
        sortorder: 0,
      },
      {
        name: "Name",
        field: "title",
        cellClass: "gd_auto",
        enableHiding: false,
        resizable: true,
        width: "40%",
      },
      {
        name: "Counterparty",
        field: "counterparty",
        enableCellEdit: true,
        enableHiding: false,
        width: "16%",
        resizable: true,
      },
      {
        name: "Label",
        field: "label",
        enableCellEdit: true,
        width: "14%",
        enableHiding: false,
      },
      {
        name: "Date",
        field: "date",
        cellFilter: "date:'" + date_format + "'",
        enableCellEdit: true,
        enableHiding: false,
      },
    ];
  }

  return cols;
}

function archive_columns(date_format) {
  var cols = [];

  cols = [
    {
      name: "",
      field: "edit",
      headerCellTemplate:
        '<div   style="text-align:left; margin-top:5px;margin-left:6px;"><input type="checkbox" style="width: 15px;height: 15px;" ng-model="grid.appScope.selectall" ng-click="grid.appScope.editall($event)" ng-disabled="readonly || isloading " /></div>',
      pinnedLeft: true,
      enableColumnMoving: false,
      enableColumnResizing: false,
      sortorder: -1,
      resizable: false,
      width: 30,
    },
    {
      name: "Folder",
      field: "folder",
      enableCellEdit: false,
      enableHiding: false,
      width: "20%",
      resizable: true,
      sortorder: 0,
    },
    {
      name: "Name",
      field: "title",
      cellClass: "gd_auto",
      enableHiding: false,
      resizable: true,
      width: "29%",
      sortorder: 1,
    },
    {
      name: "Counterparty",
      field: "counterparty",
      cellClass: "gd_auto",
      enableCellEdit: true,
      enableHiding: false,
      width: "20%",
      resizable: true,
      sortorder: 2,
    },
    {
      name: "Effective date",
      field: "startdate",
      cellFilter: "date:'" + date_format + "'",
      enableCellEdit: true,
      width: "15%",
      enableHiding: false,
      sortorder: 3,
    },
    {
      name: "Termination date",
      field: "terminationdate",
      cellFilter: "date:'" + date_format + "'",
      enableCellEdit: true,
      enableHiding: false,
      cellClass: "gd_auto",
      resizable: true,
      sortorder: 4,
    },
  ];

  return cols;
}

const capitalize = (x) => x.replace(/./, (c) => c.toUpperCase());

const kebabToCamelCase = (str) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", ""));

const DEBOUNCE_DELAY = 6000;

const optionSort = (a, b) => {
  const aNum = parseFloat(a);
  const bNum = parseFloat(b);

  // sort numerical prefixes numerically
  if (a.startsWith(aNum) && b.startsWith(bNum)) {
    if (aNum < bNum) {
      return -1;
    } else if (aNum > bNum) {
      return 1;
    }
  }

  const aLower = a.toLowerCase();
  const bLower = b.toLowerCase();

  // when number parts or equal or nonexistent, sort alphabetically,
  // case-insensitive
  if (aLower < bLower) {
    return -1;
  } else if (aLower > bLower) {
    return 1;
  }

  // for case-insensitive matches, group like cases together so identical
  // strings sort together
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  }

  return 0;
};

const graphqlDate = (d) => (d ? d.format("YYYY-MM-dd") : d);

function parseNullableDate(date) {
  return date ? parseISO(date) : null;
}

const DEFAULT_ITEMS_PER_PAGE = "25";
const DEFAULT_SEARCH_PARAMS = {
  filters: [],
  search_terms: [],
  sortby: "",
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
};

const LOCAL_CACHES = [
  "remindersto",
  "emailreportto",
  "partys", // old
  "parties", // new
  "orgemailto",
  "detail_states",
  "orgusers",
  "governing_law",
  "currencies",
  "acct_number",
  "approved_by_txt",
  "partys_mycompany",
  "form_version",
  "disputes_jurisdiction",
  "date_format",
];

const clearLocalCaches = () =>
  LOCAL_CACHES.map((key) => localObjStore.remove(key));

function clearCachedData(orgId) {
  localObjStore.remove("org." + orgId);
  clearLocalCaches();
}
const microsoftSignInPopup = () =>
  window.msal_uaa.loginPopup({ scopes: ["openid", "profile", "email"] });

const valueIfString = (x) => (_.isString(x) ? x : null);

module.exports = {
  ACCOUNTROLE_QUERY,
  CATEGORIZATION_REQUEST_QUERY,
  CHANGE_CONTRACT_TYPE_MUTATION,
  CONTRACT_QUERY,
  CONTRACT_FORMS_CONTRACT_QUERY,
  DEBOUNCE_DELAY,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_SEARCH_PARAMS,
  LOCAL_CACHES,
  ORG_FORMS_QUERY,
  ORG_CONTRACT_TYPES_QUERY,
  SelectText,
  archive_columns,
  capitalize,
  clearLocalCaches,
  clearCachedData,
  decodeHtmlEntities,
  defaultColumns,
  dynamicSort,
  timeAgo,
  format_date_string,
  getTimeZone,
  graphqlDate,
  invalidateRelayStore,
  kebabToCamelCase,
  loadQuery,
  microsoftSignInPopup,
  optionSort,
  parseNullableDate,
  parseerrordata,
  preloadQuery,
  validateEmail,
  valueIfString,
  xonmouseout,
  xonmouseover,
};
